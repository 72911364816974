import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import classNames from "classnames"
import { FluidObject } from "gatsby-image"
import {
  CoramDeoPageQuery,
  ContentfulPromotionUnion
} from "../../../graphql/types"
import { defaultMetadata, PageOpenGraphImage } from "../../core/constants"
import styles from "./coram-deo.module.scss"
import { PageProps } from "@src-types/page"
import { Page } from "@components/page/page"
import { FeaturedResources } from "@components/featured-resources/featured-resources"
import { Markdown } from "@components/markdown/markdown"
import { mapSourceFeaturedItemsToView } from "@features/home-page/mappers/featured-items-mapper"
import { mapPromotion } from "@features/home-page/mappers/promotion-mapper"
import { ProductHeader } from "@features/store/components/product-header/product-header"
import {
  PromoHeader,
  PromoHeaderProps
} from "@components/promo-header/promo-header"
import { mapAboutFounderToView } from "@features/about/mappers/about-founder-mapper"
import { FounderDetails } from "@features/about/components/founder/founder"
import { SupportBox } from "@features/about/components/support-box/support-box"

export const CoramDeoPage: FunctionComponent<PageProps<CoramDeoPageQuery>> = (
  props
) => {
  const {
    pageTitle,
    opening,
    body,
    headerQuote,
    headerAttribution,
    headerButtonText,
    featuredResources,
    productWithCustomMessaging,
    founderContentBlock,
    founderResources,
    founderFeaturedWorks,
    donateCTAImage,
    donateCTAOverline,
    donateCTAHeading,
    donateCTAButtonText,
    donateCTAButtonLink,
    metadata
  } = props?.data?.contentfulCoramDeoPage || {}

  const metadataOverrides = {
    title: metadata?.title || pageTitle || defaultMetadata.title,
    description: metadata?.description || defaultMetadata.description,
    openGraphImage: metadata?.image?.fixed?.src || PageOpenGraphImage.CoramDeo
  }

  const openingText = opening?.childMarkdownRemark?.html
  const bodyText = body?.childMarkdownRemark?.html
  const mappedFeaturedResources =
    mapSourceFeaturedItemsToView(featuredResources)
  const mappedPromotion = productWithCustomMessaging
    ? mapPromotion(productWithCustomMessaging as ContentfulPromotionUnion)
    : null
  const { productPromotion, customPromotion } = mappedPromotion || {}
  const founder = mapAboutFounderToView({
    founderContentBlock,
    founderFeaturedWorks,
    founderResources
  })
  const donateCTA = {
    title: donateCTAHeading || "",
    subtitle: donateCTAOverline || "",
    fluidImage: donateCTAImage?.fluid as FluidObject,
    button: {
      url: donateCTAButtonLink || "",
      text: donateCTAButtonText || ""
    }
  }

  const headerVideoOptions = {
    posterVideoId:
      props.data?.contentfulCoramDeoPage?.coramDeoPosterVideo?.brightcoveId ||
      "",
    videoId:
      props.data?.contentfulCoramDeoPage?.coramDeoVideo?.brightcoveId || "",
    heading: "Coram Deo",
    quote: headerQuote?.childMarkdownRemark?.rawMarkdownBody || "",
    attribution: headerAttribution || "",
    buttonText: headerButtonText || ""
  }

  return (
    <Page
      {...props}
      metadata={{
        ...defaultMetadata,
        ...metadataOverrides
      }}
      isInfoIconShown={true}
      pageOptions={{
        headerVariant: "video",
        headerVideoOptions
      }}
    >
      {openingText && (
        <div className={styles.oliveBackground}>
          <Markdown
            content={openingText}
            className={classNames(styles.markdownText, styles.opening)}
          />
        </div>
      )}
      {mappedFeaturedResources && (
        <FeaturedResources
          className={styles.featured}
          list={mappedFeaturedResources}
          labelCollapse
          labelVariant={"light"}
        />
      )}
      {bodyText && (
        <Markdown
          content={bodyText}
          className={classNames(styles.markdownText, styles.body)}
        />
      )}
      {mappedPromotion && (
        <div className={styles.promotionWrapper}>
          {productPromotion ? (
            <ProductHeader
              product={productPromotion.product}
              variant={productPromotion.product.activeVariant}
              headline={productPromotion.headline}
              subhead={productPromotion.subhead}
              buttonText={productPromotion.buttonText}
              showSecondaryLink={productPromotion.showSecondaryLink}
              secondaryLinkText={productPromotion.secondaryLinkText}
              headerLocation="coram-deo"
            />
          ) : (
            <PromoHeader {...(customPromotion as PromoHeaderProps)} />
          )}
        </div>
      )}
      {founder && (
        <FounderDetails details={founder} className={styles.founder} />
      )}
      <SupportBox
        {...donateCTA}
        className={styles.donateCTA}
        boxShadowVariant="green"
      />
    </Page>
  )
}

export default CoramDeoPage

export const query = graphql`
  query CoramDeoPage {
    contentfulCoramDeoPage {
      pageTitle
      metadata {
        ...Metadata
      }
      coramDeoPosterVideo {
        brightcoveId
      }
      coramDeoVideo {
        brightcoveId
      }
      headerQuote {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      headerAttribution
      headerButtonText
      opening {
        childMarkdownRemark {
          html
        }
      }
      featuredResources {
        ... on ContentfulArticle {
          ...FeaturedArticle
        }
        ... on ContentfulDevotional {
          ...FeaturedDevotional
        }
        ... on ContentfulPost {
          ...FeaturedPost
        }
        ... on ContentfulSeries {
          ...FeaturedSeries
        }
        ... on ContentfulSermon {
          ...FeaturedSermon
        }
        ... on ContentfulMediaResource {
          ...FeaturedMediaResource
        }
        ... on ContentfulVideo {
          ...FeaturedVideo
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      productWithCustomMessaging {
        ...Promotion
      }
      founderContentBlock {
        image {
          ...ContentfulImage
        }
        title {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        description {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        urlLink
      }
      founderFeaturedWorks {
        ...VariantWithProduct
      }
      founderResources {
        ... on ContentfulArticle {
          ...FeaturedArticle
        }
        ... on ContentfulDevotional {
          ...FeaturedDevotional
        }
        ... on ContentfulSeries {
          ...FeaturedSeries
        }
        ... on ContentfulSermon {
          ...FeaturedSermon
        }
        ... on ContentfulPost {
          ...FeaturedPost
        }
        ... on ContentfulCollection {
          ...FeaturedCollection
        }
      }
      donateCTAImage {
        fluid(maxWidth: 600) {
          ...GatsbyContentfulFluid
        }
      }
      donateCTAOverline
      donateCTAHeading
      donateCTAButtonText
      donateCTAButtonLink
    }
  }
`
